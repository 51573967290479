<template>
  <div id="page-wrapper">
    <MainSection @locale-change="localeChange"></MainSection>
    <Mission></Mission>
    <Statistic></Statistic>
    <Complex></Complex>
    <WhyToChoose></WhyToChoose>
    <Tasks></Tasks>
    <HowItWorks></HowItWorks>
    <Cases></Cases>
    <Technology></Technology>
    <Partners></Partners>
    <HowToFind :locale="locale"></HowToFind>
    <Footer></Footer>
  </div>
</template>

<script>
import MainSection from "../section/MainSection";
import Mission from "../section/Mission";
import Complex from "../section/Complex";
import WhyToChoose from "../section/WhyToChoose";
import Tasks from "../section/Tasks";
import HowItWorks from "../section/HowItWorks";
import Cases from "../section/Cases";
import Technology from "../section/Technology";
import Partners from "../section/Partners";
import HowToFind from "../section/HowToFind";
import Footer from "../section/Footer";
import Statistic from "../section/Statistic";

export default {
  name: "Main",
  components: {
    Statistic,
    MainSection,
    Mission,
    Complex,
    WhyToChoose,
    Tasks,
    HowItWorks,
    Cases,
    Technology,
    Partners,
    HowToFind,
    Footer
  },
  data() {
    return {
      options: {
        scrollingSpeed: 300,
        navigation: true,
        navigationPosition: 'right',
        navigationTooltips: ['firstSlide', 'secondSlide'],
        showActiveTooltip: true,
      },
      locale: localStorage.getItem('locale') || 'RU'
    }
  },
  methods: {
    localeChange() {
      this.locale = localStorage.getItem('locale')
    }
  }
}
</script>

<style lang="scss">

@media (min-width: 600px) {
  html {
    scroll-snap-type: y proximity;
    scroll-behavior: smooth;
  }

  .section {
    scroll-snap-align: start;
  }
}

</style>
